import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AELogo from "../assets/A-ELogo.png";
import { RiCloseLine } from "react-icons/ri";
import GlobalDiscountTooltip from "./ToolTip/ToolTip";
import "./Navbar.css";
import { IoMenuOutline } from "react-icons/io5";

const Navbar = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
    document.body.style.overflow = isMenuVisible ? "auto" : "hidden";
  };

  const closeMenu = () => {
    setMenuVisible(false);
    document.body.style.overflow = "auto";
  };

  const HandleDownloadSignUp = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.angel.earth.angel.app",
        "_blank"
      );
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      window.open(
        "https://apps.apple.com/us/app/angel-app/id6473820385",
        "_blank"
      );
    } else {
      window.open("https://dashboard.angel-earth.com/signup", "_blank");
    }
  };

  const handleDownload = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.angel.earth.angel.app",
        "_blank"
      );
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      window.open(
        "https://apps.apple.com/us/app/angel-app/id6473820385",
        "_blank"
      );
    } else {
      window.open("https://dashboard.angel-earth.com/login", "_blank");
    }
  };

  const mainClassName = "Nav-Bar-Div-landing";
  const midChild = mainClassName + "-Mid-Child";
  const LastChild = mainClassName + "-Last-Child";

  return (
    <>
      <div>
        <GlobalDiscountTooltip />
      </div>
      <div className={mainClassName}>
        <a href="/">
          <img
            src={AELogo}
            width={160}
            height={60}
            style={{ position: "relative", left: 12 }}
            id="nav-logo"
            alt="Logo"
          />
        </a>

        <div className={midChild}>
          <NavLink
            exact
            to="/"
            className="NavLinks-Text"
            style={{ color: "black" }}
            activeClassName="active"
            onClick={closeMenu}
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className="NavLinks-Text"
            style={{ color: "black" }}
            activeClassName="active"
            onClick={closeMenu}
          >
            About
          </NavLink>
          <NavLink
            to="/services"
            className="NavLinks-Text"
            style={{ color: "black" }}
            activeClassName="active"
            onClick={closeMenu}
          >
            Products
          </NavLink>
          <NavLink
            to="/pricing"
            className="NavLinks-Text"
            style={{ color: "black" }}
            activeClassName="active"
            onClick={closeMenu}
          >
            Pricing
          </NavLink>

          <NavLink
            to="/contact"
            className="NavLinks-Text"
            style={{ color: "black" }}
            activeClassName="active"
            onClick={closeMenu}
          >
            Contact
          </NavLink>

          <NavLink
            to="/why-angel-app"
            className="NavLinks-Text"
            style={{ color: "black" }}
            activeClassName="active"
            onClick={closeMenu}
          >
            Why Angel-App
          </NavLink>

          {/* <NavLink
          to="/documents"
          className="NavLinks-Text"
          style={{ color: "black" }}
          activeClassName="active"
          onClick={closeMenu}
        >
          Documents
        </NavLink> */}
        </div>

        <div className={LastChild}>
          <div className={LastChild + `-LoginBtn`}>
            {/* <a
              href="https://dashboard.angel-earth.com/login"
              className="NavLinks-Text"
              style={{ color: "black" }}
              activeClassName="active"
              onClick={closeMenu}
            >
              Login
            </a> */}

            <span
              className="NavLinks-Text"
              style={{ color: "black", cursor: "pointer" }}
              activeClassName="active"
              onClick={() => {
                handleDownload();
                closeMenu();
              }}
            >
              Login
            </span>
          </div>
          <div className={LastChild + `-Signup`}>
            {/* <a
              href="https://dashboard.angel-earth.com/signup"
              className="NavLinks-Text"
              style={{ color: "black" }}
              activeClassName="active"
              onClick={closeMenu}
            >
              SignUp
            </a> */}

            <span
              className="NavLinks-Text"
              style={{ color: "black", cursor: "pointer" }}
              activeClassName="active"
              onClick={() => {
                HandleDownloadSignUp();
                closeMenu();
              }}
            >
              SignUp
            </span>
          </div>

          <div className="NavIconDiv" onClick={toggleMenu}>
            {isMenuVisible ? (
              <RiCloseLine size={30} style={{ color: "black" }} />
            ) : (
              <IoMenuOutline size={30} style={{ color: "black" }} />
            )}
          </div>
        </div>

        {isMenuVisible && (
          <div
            className="menu-overlay"
            onClick={closeMenu}
            style={{ zIndex: "999" }}
          >
            <div className="menu">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <a href="/">
                    <img
                      src={AELogo}
                      width={160}
                      height={60}
                      style={{ position: "relative", left: 12 }}
                      className="respo-navLogo"
                      alt="Logo"
                    />
                  </a>
                </div>

                <div className="NavIconDiv" onClick={toggleMenu}>
                  {isMenuVisible ? (
                    <RiCloseLine size={30} style={{ color: "black" }} />
                  ) : (
                    <IoMenuOutline size={30} style={{ color: "black" }} />
                  )}
                </div>
              </div>
              <ul>
                <li>
                  <NavLink exact to="/" activeClassName="active">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/about" activeClassName="active">
                    About
                  </NavLink>
                </li>{" "}
                <li>
                  <NavLink to="/services" activeClassName="active">
                    Products
                  </NavLink>
                </li>{" "}
                <li>
                  <NavLink to="/pricing" activeClassName="active">
                    Pricing
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact" activeClassName="active">
                    Contact
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/why-angel-app" activeClassName="active">
                    Why Angel-App
                  </NavLink>
                </li>
                {/* <li>
                <NavLink to="/documents" activeClassName="active">
                  Documents
                </NavLink>
              </li> */}
                <li>
                  {/* <a
                    href="https://dashboard.angel-earth.com/login"
                    className="NavLinks-Text"
                    style={{ color: "black" }}
                    activeClassName="active"
                    onClick={closeMenu}
                  >
                    Login
                  </a> */}

                  <span
                    className="NavLinks-Text"
                    style={{ color: "black", cursor: "pointer" }}
                    activeClassName="active"
                    onClick={() => {
                      handleDownload();
                      closeMenu();
                    }}
                  >
                    Login
                  </span>
                </li>
                <li>
                  {/* <a
                    href="https://dashboard.angel-earth.com/signup"
                    className="NavLinks-Text"
                    style={{ color: "black" }}
                    activeClassName="active"
                    onClick={closeMenu}
                  >
                    SignUp
                  </a> */}

                  <span
                    className="NavLinks-Text"
                    style={{ color: "black", cursor: "pointer" }}
                    activeClassName="active"
                    onClick={() => {
                      HandleDownloadSignUp();
                      closeMenu();
                    }}
                  >
                    SignUp
                  </span>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
