import React from "react";
import Pricing1 from "../Pricing/Pricing-1/Pricing1";
// import Pricing2 from "../Pricing/Pricing-2/Pricing2";
import Privacy from "../Pricing/PricingCards/Privacy/Privacy";
import MoneyBack from "../Pricing/moneyback/MoneyBack";
function PricingNavigateStorage() {
  // const basic=19;
  // const standard=50;
  // const recommended=25
  // const premium =65
  const name = "Storage";
  return (
    <>
      <Pricing1 name={name} />

      {/* <Pricing2
        basic={basic}
        standard={standard}
        recommended={recommended}
        premium={premium}
        pricingval="STORAGE"
      /> */}

      <div className="why-angel-app-container">
        <h2 style={{ textAlign: "center" }}>Storage</h2>

        <p className="text-para-dtrans">
          Preserve, organize, and secure your digital documents effortlessly
          with Angel-App's Storage feature. Welcome to a smarter way of managing
          your documents! With Angel-App's intuitive Storage feature, businesses
          and individuals alike can streamline their document management
          processes, ensuring easy access, enhanced security, and seamless
          organization for all their digital files. For a limited time get a
          free gig of space when you invite 10 of your friends!
        </p>
      </div>

      <Privacy />
      <MoneyBack />
    </>
  );
}

export default PricingNavigateStorage;
