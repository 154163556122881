import React from "react";
import PricingSecondarylinks from "../PricingSecondarylinks/PricingSecondarylinks";
import OneDayCampaign from "../Pricing/onedaycamp/OneDayCampaign";
// import Pricing2 from '../Pricing/Pricing-2/Pricing2'
import Privacy from "../Pricing/PricingCards/Privacy/Privacy";
import MoneyBack from "../Pricing/moneyback/MoneyBack";
import PricingCompOne from "./PricingCompOne/PricingCompOne";

function PricingNavigateOneday() {
  let name = "one day campaign";
  return (
    <>
      {/* <PricingCompOne name={name} /> */}

      {/* <PricingSecondarylinks/> */}
      {/* <OneDayCampaign /> */}
      {/* <Pricing2/> */}
      <div className="why-angel-app-container">
        <h2 style={{ textAlign: "center" }}>One Day Campaign</h2>

        <p className="text-para-dtrans">
          Welcome to Angel-App's 1 Day Campaign—a thrilling journey into daily
          savings and exciting deals for both consumers and businesses. Immerse
          potential customers in short-term promotional campaigns crafted to
          bring exclusive offers, discounts, and irresistible deals. With
          Angel-App's innovative platform, businesses can create dynamic
          promotions that generate buzz, foster excitement, and drive customer
          engagement, making each day an opportunity for savings and success.
        </p>
      </div>
      {/* <Privacy />
      <MoneyBack /> */}
    </>
  );
}

export default PricingNavigateOneday;
