import React from "react";
import DoneIcongrey from "../../../assets/DoneIcongrey.png";
import Privacy from "../PricingCards/Privacy/Privacy";
import MoneyBack from "../moneyback/MoneyBack";
import "./OneDayCampaign.css";
function OneDayCampaign() {
  // const redirectToLogin = (url) => {
  //   window.open(url, "_blank");
  // };

  const handleDownload = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.angel.earth.angel.app",
        "_blank"
      );
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      window.open(
        "https://apps.apple.com/us/app/angel-app/id6473820385",
        "_blank"
      );
    } else {
      window.open("https://dashboard.angel-earth.com/login", "_blank");
    }
  };

  const cardData = [
    {
      region: "City Wide",
      // amount: "$ 75.00",
      amount: "Free",
      features: [
        "Dynamic Promotional Strategy",
        "Adaptive Promotional Algorithms",
        "Geographic Customization",
        "Exclusive City Booking",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        // "City Wide = $75.00/ Per Day",
        // "Push Radius $1.00/ Per Push",
        "City Wide = Free / Per Day",
        "Push Radius Free / Per Push",
      ],
    },
    {
      region: "Province Wide",
      // amount: "$ 100.00",
      amount: "Free",
      features: [
        "Dynamic Promotional Strategy",
        "Adaptive Promotional Algorithms",
        "Geographic Customization",
        "Exclusive Province Booking",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        // "City Wide = $100.00/ Per Day",
        // "Push Radius $1.00/ Per Push",
        "City Wide = Free / Per Day",
        "Push Radius Free / Per Push",
      ],
    },
    {
      region: "Country Wide",
      // amount: "$ 150.00",
      amount: "Free",
      features: [
        "Dynamic Promotional Strategy",
        "Adaptive Promotional Algorithms",
        "Geographic Customization",
        "Exclusive Country Booking",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        // "City Wide = $150.00/ Per Day",
        // "Push Radius $1.00/ Per Push",
        "City Wide = Free / Per Day",
        "Push Radius Free / Per Push",
      ],
    },
  ];

  return (
    <>
      <div style={{ marginTop: "2rem", position: "relative" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            zIndex: "20",
            width: "100%",
          }}
        >
          <p style={{ fontSize: "3em", color: "#389E47", fontWeight: "600" }}>
            COMING SOON
          </p>
        </div>

        <div style={styles.DtransferBtn}>
          <small>One Day Campaign</small>
        </div>

        <p
          style={{
            fontSize: "2.4em",
            fontWeight: "bold",
            margin: 4,
            textAlign: "center",
          }}
          className="Respo-PricingHeading"
        >
          Choose a plan that fits <br /> you best.
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "2.5rem",
            flexWrap: "wrap",
          }}
          className="Respo-PricingCardsOneday blur-content"
        >
          {cardData.map((card, index) => (
            <div className="card-one-day" key={index}>
              <div className="top-items">
                <div></div>
              </div>
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="citywide theregion"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>{card.region}</span>
                  </div>
                  <div className="values pricing-values">
                    <h2 className="payamount pricing-amount Headings">
                      {card.amount}
                    </h2>
                    {/* <span className="spantxt">/ per Day</span> */}
                  </div>
                </div>
                <div style={{ marginTop: "2.6rem" }}>
                  <ul className="lists pricing-list">
                    {card.features.map((feature, index) => (
                      <div key={index} className="li-data">
                        <div
                          style={{
                            display: "flex",
                            gap: "13px",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={DoneIcongrey}
                            style={{
                              maxHeight: "20px",
                              maxWidth: "20px",
                              marginBottom: "8px",
                            }}
                            alt="done-icon-gray"
                          />
                          {/* <span className="featurepricing">{feature}</span> */}
                          <span className="featurepricing">
                            {feature.includes("Free") ? (
                              <b>{feature}</b>
                            ) : (
                              feature
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="btn-div pricingBTN-div">
                <button
                  className="btn OneDayPricing-Btn"
                  onClick={handleDownload}
                >
                  Explore
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="why-angel-app-container">
        <h2
          style={{ textAlign: "center", marginTop: "3rem" }}
          className="Headings"
        >
          One Day Campaign
        </h2>

        <p className="text-para-dtrans">
          Welcome to Angel-App's 1 Day Campaign—a thrilling journey into daily
          savings and exciting deals for both consumers and businesses. Immerse
          potential customers in short-term promotional campaigns crafted to
          bring exclusive offers, discounts, and irresistible deals. With
          Angel-App's innovative platform, businesses can create dynamic
          promotions that generate buzz, foster excitement, and drive customer
          engagement, making each day an opportunity for savings and success.
        </p>
      </div>
      <Privacy />
      <MoneyBack />
    </>
  );
}
const styles = {
  DtransferBtn: {
    width: "8.7em",
    borderRadius: "1.2em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.9",
    color: "#00C7FF",
    border: "#00C7FF solid 1px",
    margin: "auto",
    paddingLeft: "16px",
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingRight: "14px",
  },

  heading: {
    textAlign: "center",
  },
};
export default OneDayCampaign;
