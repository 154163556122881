import React from "react";
import DoneIcongrey from "../../../assets/DoneIcongrey.png";
import Privacy from "../../Pricing/PricingCards/Privacy/Privacy";
import MoneyBack from "../../Pricing/moneyback/MoneyBack";
import "./DtransPricing.css";
function DtransferPricing() {
  // const redirectToLogin = (url) => {
  //   window.open(url, "_blank");
  // };

  const handleDownload = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.angel.earth.angel.app",
        "_blank"
      );
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      window.open(
        "https://apps.apple.com/us/app/angel-app/id6473820385",
        "_blank"
      );
    } else {
      window.open("https://dashboard.angel-earth.com/login", "_blank");
    }
  };

  const cardData = [
    {
      region: "1 POS",
      // amount: "$ 200.00",
      amount: "Free",
      features: [
        "Digital Receipts",
        "Expense Tracking Insights",
        "Invoice Digitization",
        "Private Secure Cloud Storage",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        // "Per scan d-Transfer - $0.07CDN",
        // "1 POS = $200.00 /Month",
        "Per scan d-Transfer = Free",
        "1 POS = Free / Month",
      ],
    },

    {
      region: "2-4 POS",
      amount: "Free",
      // amount: "$ 180.00",
      features: [
        "Digital Receipts",
        "Expense Tracking Insights",
        "Invoice Digitization",
        "Private Secure Cloud Storage",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        // "Per scan d-Transfer - $0.07CDN",
        // "2-4 POS = $180.00 /Month Per Pos",
        "Per scan d-Transfer = Free",
        "2-4 POS = Free / Month Per Pos",
      ],
    },
    {
      region: "5-10 POS",
      // amount: "$ 160.00",
      amount: "Free",
      features: [
        "Digital Receipts",
        "Expense Tracking Insights",
        "Invoice Digitization",
        "Private Secure Cloud Storage",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        // "Per scan d-Transfer - $0.07CDN",
        // "5-10 POS = $160.00 /Month Per Pos",
        "Per scan d-Transfer = Free",
        "5-10 POS = Free / Month Per Pos",
      ],
    },

    {
      region: "11<em> Plus</em> POS",
      amount: "Free",
      // amount: "$ 140.00",
      features: [
        "Digital Receipts",
        "Expense Tracking Insights",
        "Invoice Digitization",
        "Private Secure Cloud Storage",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        // "Per scan d-Transfer - $0.07CDN",
        // "11 + POS = $140.00 /Month Per Pos",
        "Per scan d-Transfer = Free",
        "11 + POS = Free / Month Per Pos",
      ],
    },
  ];

  return (
    <>
      <div style={{ marginTop: "2rem", position: "relative" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            zIndex: "20",
            width: "100%",
          }}
        >
          <p style={{ fontSize: "3em", color: "#389E47", fontWeight: "600" }}>
            COMING SOON
          </p>
        </div>
        <div style={styles.DtransferBtn}>
          <p>dTransfer</p>
        </div>

        <p
          style={{
            fontSize: "2.4em",
            fontWeight: "bold",
            margin: 4,
            textAlign: "center",
          }}
          className="Respo-PricingHeading"
        >
          Choose a plan that fits <br /> you best.
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "2.5rem",
            flexWrap: "wrap",
          }}
          className="Respo-PricingCards blur-content"
        >
          {cardData.map((card, index) => (
            <div className="card-one-day card-dtrans" key={index}>
              <div className="top-items">
                <div></div>
              </div>
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="citywide theregion"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: card.region }}
                    ></span>
                  </div>

                  <div className="values pricing-values">
                    <h2 className="payamount pricing-amount Headings">
                      {card.amount}
                    </h2>
                    {/* <span className="spantxt">/ per Month</span> */}
                  </div>
                </div>
                <div style={{ marginTop: "2.6rem" }}>
                  <ul className="lists pricing-list">
                    {card.features.map((feature, index) => (
                      <div key={index} className="li-data">
                        <div
                          style={{
                            display: "flex",
                            gap: "13px",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={DoneIcongrey}
                            style={{
                              maxHeight: "20px",
                              maxWidth: "20px",
                              marginBottom: "7px",
                            }}
                            alt="done-icon-gray"
                          />
                          {/* <span className="featurepricing">{feature}</span> */}
                          <span className="featurepricing">
                            {feature.includes("Free") ? (
                              <b>{feature}</b>
                            ) : (
                              feature
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="btn-div pricingBTN-div">
                <button className="btn pricing-btn" onClick={handleDownload}>
                  Explore
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="why-angel-app-container">
        <h2 style={styles.heading} className="Headings">
          dTransfer
        </h2>

        <p className="text-para-dtrans ">
          dTransfer is revolutionizing the business landscape by fundamentally
          reshaping how organizations manage documents, optimizing internal
          processes, and ensuring robust data security measures. Through
          innovative solutions, we empower businesses to streamline their
          operations, increase efficiency, and adapt to the evolving demands of
          the digital age. Our comprehensive suite of services encompasses
          document digitization, workflow automation, and encrypted storage
          solutions, enabling seamless collaboration and access to critical
          information from anywhere, at any time. By leveraging cutting-edge
          technologies and industry best practices, we enable businesses to
          transcend traditional barriers, unlock new opportunities, and achieve
          sustainable growth. With dTransfer as their trusted partner,
          businesses can confidently navigate the complexities of document
          management, driving productivity and success in an increasingly
          competitive market landscape.
        </p>
      </div>
      <Privacy />
      <MoneyBack />
    </>
  );
}

const styles = {
  DtransferBtn: {
    width: "6.3em",
    height: "2.2em",
    borderRadius: "1.2em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.9em",
    color: "#00C7FF",
    border: "#00C7FF solid 1px",
    margin: "auto",
  },

  heading: {
    textAlign: "center",
  },
};

export default DtransferPricing;
