import React, { useState, useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import { useForm, Controller } from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import "./ContactUs.css";
function ContactUs() {
  const phoneRegExp = /^\d{11}$/;
  const phoneInputRef = useRef(null);
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = (data) => {
    setIsLoading(true);
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phoneNumber,
      subject: data.subject,
      message: data.message,
      sendNewsLetter: data.sendNewsLetter,
    };

    fetch(
      "https://1u1ykhoaj4.execute-api.us-west-2.amazonaws.com/Dev/contactus",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => {
        setIsLoading(false);
        if (!response.ok) {
          throw new Error(`Server Error: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
        setShowSuccess(true);
        setShowError(false);
        reset();
      })
      .catch((error) => {
        console.error("Error:", error);
        setShowError(true);
        setShowSuccess(false);
      });
  };

  useEffect(() => {
    if (showError || showSuccess) {
      const timeout = setTimeout(() => {
        setShowError(false);
        setShowSuccess(false);
      }, 10000);

      return () => clearTimeout(timeout);
    }
  }, [showError, showSuccess]);

  const validatePhoneNumber = (value) => {
    if (!value || !phoneRegExp.test(value)) {
      return "Invalid phone number. Please enter a valid phone number.";
    }
    return undefined;
  };
  function closeError() {
    setShowError(false);
  }
  function closeSuccess() {
    setShowSuccess(false);
  }
  return (
    <div className="contact-containers">
      <form onSubmit={handleSubmit(onSubmit)} className="contact-container">
        <div className="left-side">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "6rem",
            }}
            id="contact-above-container"
          >
            <h1 className="Form-name">CONTACT US</h1>
            <div className="table-hrz-line"></div>
            <table className="info-table">
              <tbody>
                <tr>
                  <td className="td-key">Email:</td>
                  <td className="td-val">
                    <a href="mailto:Support@angel-earth.com" className="td-val">
                      welcome@angel-earth.com
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="td-key">Twitter:</td>
                  <td className="td-val">
                    <a
                      href="https://twitter.com/AngelEarthCorp1"
                      target="_blank"
                      className="td-key"
                    >
                      Angel-Earth
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="td-key">Instagram:</td>

                  <td className="td-val">
                    <a
                      href="https://www.instagram.com/true.angel.app/?hl=en"
                      target="_blank"
                      className="td-key"
                    >
                      true.angel.app
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="td-key">Facebook:</td>
                  <td className="td-val">
                    <a
                      href="https://www.facebook.com/profile.php?id=100063787165466"
                      target="_blank"
                      className="td-key"
                    >
                      Angel-Earth
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="td-key">TikTok:</td>
                  <td className="td-val">
                    <a
                      href="https://www.tiktok.com"
                      target="_blank"
                      className="td-key"
                    >
                      Angel-Earth
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="right-side">
          <div className="shade">
            <div className="form-header">
              <h2 className="Headings">Get in Touch</h2>
              <p>Our friendly team would love to hear from you</p>
            </div>
            <div className="form-content">
              <div className="form-row" style={{ position: "relative" }}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: "First Name is required" }}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="text"
                        placeholder="First Name"
                        required
                      />

                      {errors.firstName && (
                        <span
                          style={{
                            position: "absolute",
                            bottom: "-17px",
                            color: "red",
                            left: "2px",
                            fontSize: "10.5px",
                          }}
                          id="FnErr"
                        >
                          {errors.firstName.message}
                        </span>
                      )}
                    </>
                  )}
                />

                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: "Last Name is required" }}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="text"
                        placeholder="Last Name"
                        required
                      />
                      {errors.lastName && (
                        <span
                          style={{
                            position: "absolute",
                            bottom: "-17px",
                            color: "red",
                            right: "29%",
                            fontSize: "10.5px",
                          }}
                          className="RSPOErr"
                        >
                          {errors.lastName.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="form-row" style={{ position: "relative" }}>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: "Email is required" }}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="email"
                        placeholder="Email"
                        required
                        className="form-control email-inps "
                        style={{ width: "100%" }}
                      />
                      {errors.email && (
                        <span
                          style={{
                            position: "absolute",
                            bottom: "-17px",
                            color: "red",
                            left: "2px",
                            fontSize: "10.5px",
                          }}
                          className="RSPOErr"
                        >
                          {errors.email.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="form-row" style={{ position: "relative" }}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: "Phone number is required",
                    validate: validatePhoneNumber,
                  }}
                  render={({ field }) => (
                    <>
                      <PhoneInput
                        {...field}
                        country={"us"}
                        value={field.value}
                        onChange={field.onChange}
                        autoFormat
                        ref={phoneInputRef}
                        inputStyle={{ width: "100%" }}
                      />
                      {errors.phoneNumber && (
                        <span
                          style={{
                            position: "absolute",
                            bottom: "-17px",
                            color: "red",
                            left: "2px",
                            fontSize: "10.5px",
                          }}
                          className="RSPOErr"
                        >
                          {errors.phoneNumber.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="form-row">
                <input
                  {...register("subject", { required: "Subject is required" })}
                  type="text"
                  placeholder="Enter Subject Line"
                  required
                />
              </div>
              <div className="form-row">
                <textarea
                  {...register("message", { required: "Message is required" })}
                  placeholder="Message"
                  rows="10"
                  required
                  className="textarea-contactpage"
                ></textarea>
              </div>
              <div className="form-row form-row-btns">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    {...register("sendNewsLetter")}
                    id="checkBox"
                    onClick={(e) => e.stopPropagation()}
                  />
                  <label
                    htmlFor="checkBox"
                    style={{
                      color: "#898989",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      style={{ color: "#ffffff", fontWeight: "600" }}
                    >
                      <span style={{ color: "#898989", fontWeight: "600" }}>
                        {" "}
                        Send me your
                      </span>{" "}
                      newsletter
                    </span>
                  </label>
                </div>
                <button type="submit" className="send-button">
                  {isLoading ? "Sending..." : "Send Message"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      {showError && (
        <div className="overlay">
          <div className="popup error-popup">
            <span className="close" onClick={closeError}>
              &times;
            </span>
            <p className="error-message">
              Message Send <span style={{ color: "red" }}>Unsuccessful.</span>{" "}
              Please fill all the fields and check the checkbox.
            </p>
          </div>
        </div>
      )}
      {showSuccess && (
        <div className="overlay">
          <div className="popup success-popup">
            <span className="close" onClick={closeSuccess}>
              &times;
            </span>
            <p className="success-message">
              Message Sent Successfully. Thank you!
              <img
                src="https://media.tenor.com/t5DMW5PI8mgAAAAi/loading-green-loading.gif"
                width={"40px"}
                height={"40px"}
                alt="Processing GIF"
              />
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactUs;
