import React from "react";
import DoneIcongrey from "../../../assets/DoneIcongrey.png";
import Privacy from "./Privacy/Privacy";
import MoneyBack from "../moneyback/MoneyBack";
function PricingCardsStorage() {
  const redirectToLogin = (url) => {
    window.open(url, "_blank");
  };
  const handleDownload = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.angel.earth.angel.app",
        "_blank"
      );
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      window.open(
        "https://apps.apple.com/us/app/angel-app/id6473820385",
        "_blank"
      );
    } else {
      window.open("https://dashboard.angel-earth.com/login", "_blank");
    }
  };
  const cardData = [
    {
      region: "500MB ",
      // amount: "$ 4.99",
      amount: "Free",
      features: [
        "Document Management",
        "Flexible Storage Options",
        "Document Versioning",
        "Audit Trails and Activity Logs",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        // "1 GB of Scanned, D-Transferred Docs. = $4.99/Month",
        "500MB of Scanned, D-Transferred Docs = Free",
      ],
    },
    {
      region: "1 GB",
      amount: "$ 4.99",
      // amount: "Free",
      features: [
        "Document Management",
        "Flexible Storage Options",
        "Document Versioning",
        "Audit Trails and Activity Logs",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        "1 GB of Scanned, D-Transferred Docs. = $4.99/Month",
        // "1 GB of Scanned, D-Transferred Docs = Free",
      ],
    },
    {
      region: "2 GB",
      amount: "$ 9.99",
      // amount: "Free",

      features: [
        "Document Management",
        "Flexible Storage Options",
        "Document Versioning",
        "Audit Trails and Activity Logs",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        "2 GB of Scanned, D-Transferred Docs. = $9.99/Month",
        // "2 GB of Scanned, D-Transferred Docs = Free",
      ],
    },
    {
      region: "5 GB",
      amount: "$ 14.99",
      // amount: "Free",

      features: [
        "Document Management",
        "Flexible Storage Options",
        "Document Versioning",
        "Audit Trails and Activity Logs",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        "5 GB of Scanned, D-Transferred Docs. = $14.99/Month",
        // "5 GB of Scanned, D-Transferred Docs = Free",
      ],
    },

    {
      region: "10 GB",
      amount: "$ 24.99",
      // amount: "Free",

      features: [
        "Document Management",
        "Flexible Storage Options",
        "Document Versioning",
        "Audit Trails and Activity Logs",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        "10 GB of Scanned, D-Transferred Docs. = $24.99/Month",
        // "10 GB of Scanned, D-Transferred Docs = Free",
      ],
    },
  ];

  return (
    <>
      <div style={{ marginTop: "2rem" }}>
        <div style={styles.DtransferBtn}>
          <p>Storage</p>
        </div>

        <p
          style={{
            fontSize: "2.4em",
            fontWeight: "bold",
            margin: 4,
            textAlign: "center",
          }}
          className="Respo-PricingHeading"
        >
          Choose a plan that fits <br /> you best.
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2.5rem",
            flexWrap: "wrap",
            gap: "20px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
          className="Respo-PricingCardsOneday storagecards"
        >
          {cardData.map((card, index) => (
            <div className="card-one-day card-dtrans" key={index}>
              <div className="top-items">
                <div></div>
              </div>
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="citywide theregion"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>{card.region}</span>
                  </div>
                  <div className="values pricing-values">
                    <h2 className="payamount pricing-amount Headings">
                      {card.amount}
                    </h2>
                    {/* <span className="spantxt">/ per Month</span> */}
                  </div>
                </div>
                <div style={{ marginTop: "2.6rem" }}>
                  <ul className="lists pricing-list">
                    {card.features.map((feature, index) => (
                      <div key={index} className="li-data">
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={DoneIcongrey}
                            style={{
                              maxHeight: "20px",
                              maxWidth: "20px",
                              marginBottom: "5px",
                            }}
                            alt="done-icon-gray"
                          />
                          {/* <span className="featurepricing">{feature}</span> */}
                          <span className="featurepricing">
                            {feature.includes("Free") ? (
                              <b>{feature}</b>
                            ) : (
                              feature
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="btn-div pricingBTN-div">
                <button className="btn pricing-btn" onClick={handleDownload}>
                  Explore
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="why-angel-app-container">
        <h2
          style={{ textAlign: "center", marginTop: "3rem" }}
          className="Headings"
        >
          Storage
        </h2>

        <p className="text-para-dtrans">
          Preserve, organize, and secure your digital documents effortlessly
          with Angel-App's Storage feature. Welcome to a smarter way of managing
          your documents! With Angel-App's intuitive Storage feature, businesses
          and individuals alike can streamline their document management
          processes, ensuring easy access, enhanced security, and seamless
          organization for all their digital files. For a limited time get a
          free gig of space when you invite 10 of your friends!
        </p>
      </div>

      <Privacy />
      <MoneyBack />
    </>
  );
}
const styles = {
  selectCategoryDiv: {
    display: "flex",
    alignSelf: "flex-end",
    // paddingRight: "2em",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: "-1rem",
  },
  DtransferBtn: {
    width: "6.3em",
    height: "2.2em",
    borderRadius: "1.2em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.9em",
    color: "#00C7FF",
    border: "#00C7FF solid 1px",
    margin: "auto",
  },
  categoryLink: {
    textDecoration: "none",
  },
};
export default PricingCardsStorage;
