import React from "react";
import Privacy from "../Pricing/PricingCards/Privacy/Privacy";
import MoneyBack from "../Pricing/moneyback/MoneyBack";
// import Pricing1 from "../Pricing/Pricing-1/Pricing1";
import PricingCompOne from "./PricingCompOne/PricingCompOne";
import FlyerPricing from "./Flyerpricing/FlyerPricing";
// import Pricing2 from "../Pricing/Pricing-2/Pricing2";
function PricingnavigateFlyer() {
  // let basic = 19;
  // let standard = 50;
  // let recommended = 25;
  // let premium = 65;
  const name = "Flyer";
  return (
    <>
      <PricingCompOne name={name} />
      <FlyerPricing />

      {/* <Pricing2
        basic={basic}
        standard={standard}
        recommended={recommended}
        premium={premium}
        pricingval="FLYER"
      /> */}

      <div className="why-angel-app-container">
        <h2 style={{ textAlign: "center" }} className="Headings">
         Flyer
        </h2>

        <p className="text-para-dtrans">
          Welcome to Angel-App's vibrant digital flyer experience, where your
          business's latest promotions and highlights come to life. Produce
          interactive flyers that provide consumers with real-time updates,
          personalized recommendations, and user-friendly navigation for a
          seamless and exciting shopping experience. With Angel-App's innovative
          digital flyer platform, businesses can captivate their audience and
          drive engagement like never before, creating memorable shopping
          experiences that leave a lasting impression.
        </p>
      </div>

      <Privacy />
      <MoneyBack />
    </>
  );
}

export default PricingnavigateFlyer;
