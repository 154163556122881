import React from "react";
// import Pricing1 from "../Pricing/Pricing-1/Pricing1";
// import Pricing2 from "../Pricing/Pricing-2/Pricing2";
import PricingCompOne from "./PricingCompOne/PricingCompOne";
import Privacy from "../Pricing/PricingCards/Privacy/Privacy";
import MoneyBack from "../Pricing/moneyback/MoneyBack";
import Couponpricing from "./CouponPricing/Couponpricing";
function PricingNavigateCoupon() {
  // let basic = 19;
  // let standard = 50;
  // let recommended = 25;
  // let premium = 65;
  let name = "COUPON";
  return (
    <>
      {/* <PricingCompOne name={name} /> */}
{/* <Couponpricing/> */}
      {/* <Pricing2
        basic={basic}
        standard={standard}
        recommended={recommended}
        premium={premium}
        pricingval="COUPON"
      /> */}
        <div className="why-angel-app-container">
        <h2 style={{ textAlign: "center" }} className="Headings">
        Coupon
        </h2>

        <p className="text-para-dtrans">
        Angel-App's coupon platform empowers businesses with targeted promotions, enhancing customer loyalty and driving sales. By leveraging advanced features and strategic insights, businesses can create personalized offers that resonate with their target audience, ultimately boosting brand visibility and fostering long-term customer relationships. 
        </p>
      </div>
      <Privacy />
      <MoneyBack />
    </>
  );
}

export default PricingNavigateCoupon;
