import React from "react";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import CoyFileIMG from "./../assets/CopyFile.png";
import DataTransferIMG from "./../assets/data-transfer 1.png";
import WithWaterMarkIMG from "./../assets/watermark 1.png";
function POPUP() {
  return (
    <div style={styles.TopContainer}>
      <CancelIcon
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          cursor: "pointer",
          color: "#cfc2c2",
        }}
      />
      <h6 style={styles.Heading}>Please Select From the Following</h6>
      <div style={styles.parentsContainer}>
        <div style={styles.IconsContainer}>
          <div style={styles.EachDivIcon}>
            <img src={CoyFileIMG} height={40} width={40} alt="copyfileimg" />
            <h6 style={{ marginTop: "10px" }}>Copy Files</h6>
            <Tooltip
              title="Choose this option to keep a personal copy of the file in your cloud while sharing an identical version with the recipient."
              PopperProps={{
                placement: "right-end",
                disableInteractive: true,
              }}
            >
              <InfoIcon
                style={{
                  position: "absolute",
                  left: "3px",
                  bottom: "3px",
                  cursor: "pointer",
                  color: "#6E6A6A",
                }}
              />
            </Tooltip>
          </div>

          <div style={styles.EachDivIcon}>
            <img
              src={DataTransferIMG}
              height={40}
              width={40}
              alt="Transferfileimg"
            />
            <h6 style={{ marginTop: "10px", color: "#ffffffde" }}>
              Transfer Files
            </h6>
            <Tooltip title="Opt for this option to seamlessly transfer the file to the recipient, removing it from your cloud to ensure a one-time transfer.">
              <InfoIcon
                style={{
                  position: "absolute",
                  left: "3px",
                  bottom: "3px",

                  cursor: "pointer",
                  color: "#6E6A6A",
                }}
              />
            </Tooltip>
          </div>
          <div style={styles.EachDivIcon}>
            <img
              src={WithWaterMarkIMG}
              height={40}
              width={40}
              alt="copyfileimg"
            />
            <h6
              style={{
                marginTop: "10px",
                color: "#ffffffde",
                textAlign: "center",
              }}
            >
              Copy With WaterMark
            </h6>
            <Tooltip title="Select this choice to retain a copy in your cloud and share the file with a watermark indicating it's a copy, offering enhanced control and security.">
              <InfoIcon
                style={{
                  position: "absolute",
                  left: "3px",
                  bottom: "3px",
                  cursor: "pointer",
                  color: "#6E6A6A",
                }}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = {
  TopContainer: {
    height: "340px",
    width: "50%",
    background: "#272727",
    margin: "auto",
    border: "1px solid #ffffffde",
    position: "relative",
    marginTop: "10px",
    overflow: "hidden",
  },
  EachDivIcon: {
    height: "150px",
    width: "114px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#00000054",
    justifyContent: "center",
    position: "relative",
  },
  parentsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  IconsContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
  },
  Heading: {
    textAlign: "center",
    fontSize: "18px",
    marginTop: "17px",
    color: "#ffffffde",
    fontFamily: "poppins",
  },
};

export default POPUP;
