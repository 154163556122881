import Polygone from "../../../assets/cardPolygone.png";
import AELogo from "../../../assets/AngelLogo.png";
import DoneIcon from "../../../assets/DoneIcon.png";
import CenterPic from "../../../assets/centerPic.png";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PricingCards from "../PricingCards/PricingCardsStorage";
const Pricing1 = (props) => {
  const data = ["dTransfer","One Day Campaign", "Flyer", "Coupon","Storage",  ];

  const [currentInd, setCurrentInd] = useState(0);
  return (
    <div className="Pricing1">
      <div style={styles.selectCategoryDiv}>
        {data.map((val, index) => (
          <Link
            key={val}
            to={`/pricing/${val.toLowerCase().replace(/\s/g, "-")}`}
            style={{ textDecoration: "none" }}
          >
            <CategoryItem
              val={val}
              index={index}
              setCurrentInd={setCurrentInd}
              currentInd={currentInd}
            />
          </Link>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "1em",
          marginTop: "3em",
        }}
      >
        <div style={styles.DtransferBtn}>{props.name}</div>
        <p
          style={{
            fontSize: "2.4em",
            color: "white",
            fontWeight: "bold",
            margin: 4,
            textAlign: "center",
          }}
        >
          Choose the plan that fits <br /> best to you.
        </p>
        <p
          style={{
            fontSize: "0.8em",
            color: "white",
            textAlign: "center",
            fontFamily: "arial",
            opacity: "0.8",
            margin: 0,
          }}
        >
          Lorem ipsum dummy text lorem ipsum dummy text Lorem lorem ipsum <br />{" "}
          ipsum dummy text lorem.
        </p>
      </div>
      <PricingCards />
    </div>
  );
};

const CategoryItem = ({ val, index, setCurrentInd, currentInd }) => {
  return (
    <p
      style={{
        fontSize: "1.2em",
        color: "white",
        opacity: currentInd === index ? "1" : "0.8",
        margin: "2em 0.7em",
        borderBottom: currentInd === index ? "1px solid white" : null,
        cursor: "pointer",
        fontFamily: "Arial",
        letterSpacing: "0.1em",
      }}
      onClick={() => {
        setCurrentInd(index);
      }}
    >
      {val}
    </p>
  );
};

const styles = {
  selectCategoryDiv: {
    display: "flex",
    alignSelf: "flex-end",
    paddingRight: "2em",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop:"-1rem"
  },
  DtransferBtn: {
    width: "6.3em",
    height: "2.2em",
    borderRadius: "1.2em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.9em",
    color: "#00C7FF",
    border: "#00C7FF solid 1px",
  },
  categoryLink: {
    textDecoration: "none",
  },
};

export default Pricing1;
