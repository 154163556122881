import React from "react";
import "./Information.css";
function Information() {

  const HandleDownloadSignUp = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.angel.earth.angel.app",
        "_blank"
      );
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      window.open(
        "https://apps.apple.com/us/app/angel-app/id6473820385",
        "_blank"
      );
    } else {
      window.open("https://dashboard.angel-earth.com/signup", "_blank");
    }
  };


  return (
    <>
      <div
        className="information-container"
        style={{ marginTop: "5rem", position: "relative" }}
      >
        <p
          style={{
            position: "absolute",
            left: "-2.5rem",
            top: "4rem",
            padding: "0.85rem",
            backgroundColor: "#2F2C2C",
            rotate: "-90deg",
          }}
          id="information-tag"
        >
          Information
        </p>

        <div className="Info-Head">
          <p>Join Our Business Revolution</p>
        </div>
        <div className="text-container">
          <p>
            Through Angel-App, Angel-Earth Corporation empowers businesses and
            individuals to exchange marketing materials as well as data
            securely, conveniently, and sustainably. Embrace the power of
            digital transformation and environmental responsibility with
            Angel-App!
          </p>
        </div>

        <div className="text-container">
          <p>
            {/* Introducing Angel-App: The Future of Secure and Sustainable
            Marketing Material and Data Exchange Angel-Earth Corporation is
            thrilled to offer Angel-App, a revolutionary platform that enables
            businesses to share marketing materials and data with consumers in a
            secure, convenient, and sustainable way. Join us in embracing the
            power of digital transformation while being environmentally
            responsible. Try Angel-App today! */}
          </p>
        </div>
        <div id="info-btndiv">
          {/* <a href="https://dashboard.angel-earth.com/signup" target="_blank" rel="noreferrer" > */}
            <button className="D-transform-Info-btn" onClick={HandleDownloadSignUp}>Join</button>
          {/* </a> */}
          
        </div>
      </div>
    </>
  );
}

export default Information;
